import React from 'react';
import { Link } from 'react-router-dom';

const PricingSection = () => {
  const plans = [
    {
      title: '1 Month',
      price: 9.99,
      description: 'Flexible monthly option',
      tag: {
        text: 'Most Flexible',
        bgColor: 'bg-gray-100',
        textColor: 'text-[#ff6b6b]',
      },
    },
    {
      title: '3 Months',
      price: 24.99,
      description: 'Save 23% compared to monthly',
      tag: {
        text: 'Best Starter',
        bgColor: 'bg-gray-100',
        textColor: 'text-[#ffa500]',
      },
    },
    {
      title: '1 Year',
      price: 49.99,
      description: 'Best value! Save 55% compared to monthly',
      tag: {
        text: 'Best Value',
        bgColor: 'bg-green-100',
        textColor: 'text-green-600',
      },
      offer: '+ 2 Free Months!',
    },
    {
      title: '6 Months',
      price: 34.99,
      description: 'Save 36% compared to monthly',
      tag: {
        text: 'Popular Choice',
        bgColor: 'bg-gray-100',
        textColor: 'text-[#ff7b35]',
      },
    },
  ];

  const features = [
    'Crystal Clear 4K Resolution',
    '15,000+ Live Channels Worldwide',
    'Multi-device Support',
    'Premium Sports Packages',
    'Vast 4K HDR On-Demand Library',
    'Exclusive Live Events and Premieres',
    'Customizable Channel Lineup',
    'Zero Buffering AI Technology',
    'Multi-language Subtitle Support',
    'Regular Updates',
    '24/7 Live Chat Support',
  ];

  return (
    <section className="w-full py-16 bg-transparent text-foreground">
      <div className="container mx-auto px-4 md:px-6">
        {/* Section Header */}
        <div className="flex flex-col items-center justify-center space-y-4 text-center">
          <h2 className="text-4xl font-extrabold tracking-tight text-center mb-6 text-transparent bg-clip-text bg-gradient-to-r from-[#ff6b6b] to-[#ffa500]">
            Choose Your Subscription
          </h2> 
          <p className="max-w-[900px] text-lg text-gray-500 md:text-xl lg:text-2xl leading-relaxed">
            Select the perfect plan duration for your entertainment needs. Enjoy crystal-clear 4K streaming with our flexible subscription options.
          </p>
        </div>

        {/* Pricing Cards Grid */}
        <div className="grid gap-8 mt-12 md:grid-cols-2 lg:grid-cols-4">
          {plans.map((plan, index) => (
            <div
              key={index}
              className={`w-full flex flex-col justify-between border shadow-lg rounded-lg p-6 h-full ${
                index === 2 ? 'border-[#ffa500]' : 'border-gray-200'
              }`}
            >
              <div className="flex-grow">
                <div className="mb-4">
                  <h3 className="text-2xl font-semibold text-gray-800 flex items-center">
                    {plan.title}
                    {plan.offer && (
                      <span className="text-green-600 font-bold text-lg ml-2">
                        {plan.offer}
                      </span>
                    )}
                  </h3>
                  <p className="text-sm text-gray-500">{plan.description}</p>
                </div>
                <div className="text-4xl font-bold leading-none mb-4">
                  £{plan.price.toFixed(2)}
                  <span className="text-sm font-normal">
                    {index === 0 ? '/month' : ' total'}
                  </span>
                </div>
                {/* Tags for Each Plan */}
                <div className={`mb-4 inline-block rounded-full px-3 py-1 text-sm ${plan.tag.bgColor} ${plan.tag.textColor}`}>
                  {plan.tag.text}
                </div>
                <ul className="mt-4 space-y-2 text-sm text-gray-600">
                  {features.map((feature, featureIndex) => (
                    <li key={featureIndex} className="flex items-start">
                      <svg
                        className="w-4 h-4 text-green-500 flex-shrink-0 mt-1 mr-2"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M5 13l4 4L19 7"
                        />
                      </svg>
                      <span>{feature}</span>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="mt-6">
                <Link to="/pricing">
                  <button
                    className={`w-full py-2 px-4 rounded-lg transition-colors ${
                      index === 2
                        ? 'bg-gradient-to-r from-[#ff6b6b] to-[#ffa500] text-white hover:from-[#ff4d4d] hover:to-[#ff9100]'
                        : 'bg-gray-200 text-black font-bold hover:bg-gray-300'
                    }`}
                  >
                    Choose Plan
                  </button>
                </Link>
              </div>
            </div>
          ))}
        </div>

        {/* Call to Action for 1-Day Trial */}
        <div className="mt-12 text-center">
          <Link to="/pricing">
            <button
              className="bg-gradient-to-r from-[#ff6b6b] to-[#ffa500] text-white py-2 px-6 rounded-lg hover:from-[#ff4d4d] hover:to-[#ff9100] transition-colors"
            >
              Start 1-Day Trial for £1.99
            </button>
          </Link>
        </div>
      </div>
    </section>
  );
};

export default PricingSection;
