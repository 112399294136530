import React from 'react';

const KeyFeaturesSection = () => {
  return (
    <section className="w-full py-12 md:py-24 lg:py-32 bg-gradient-to-b from-white to-gray-100">
      <div className="container mx-auto px-4 md:px-6">
        {/* Section Heading */}
        <div className="text-center">
          <h2 className="text-4xl font-extrabold text-center mb-8 text-transparent bg-clip-text bg-gradient-to-r from-[#ff6b6b] to-[#ffa500]">
            Key Features of Flix Sphere
          </h2>
          <p className="max-w-[600px] mx-auto text-lg md:text-xl lg:text-2xl text-gray-700 mt-4">
            Discover the top features that make Flix Sphere your ultimate IPTV service provider.
          </p>
        </div>

        {/* Key Features Grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 mt-12">
          <div className="bg-white rounded-lg shadow-lg p-8 flex flex-col items-center text-center transition-transform transform hover:scale-105 hover:shadow-xl">
            <VideoIcon className="w-16 h-16 text-[#ffa500]" />
            <h3 className="text-2xl font-bold mt-4 text-black">HD/4K Streaming</h3>
            <p className="text-gray-700 mt-2">
              Enjoy crystal-clear video quality with our 4K streaming capabilities.
            </p>
          </div>

          <div className="bg-white rounded-lg shadow-lg p-8 flex flex-col items-center text-center transition-transform transform hover:scale-105 hover:shadow-xl">
            <WifiIcon className="w-16 h-16 text-[#ffa500]" />
            <h3 className="text-2xl font-bold mt-4 text-black">Buffer-Free Streaming</h3>
            <p className="text-gray-700 mt-2">
              Experience seamless, uninterrupted streaming with our advanced technology.
            </p>
          </div>

          <div className="bg-white rounded-lg shadow-lg p-8 flex flex-col items-center text-center transition-transform transform hover:scale-105 hover:shadow-xl">
            <PowerIcon className="w-16 h-16 text-[#ffa500]" />
            <h3 className="text-2xl font-bold mt-4 text-black">24/7 Customer Support</h3>
            <p className="text-gray-700 mt-2">
              Our dedicated team is available around the clock to assist you.
            </p>
          </div>

          <div className="bg-white rounded-lg shadow-lg p-8 flex flex-col items-center text-center transition-transform transform hover:scale-105 hover:shadow-xl">
            <CloudLightningIcon className="w-16 h-16 text-[#ffa500]" />
            <h3 className="text-2xl font-bold mt-4 text-black">Instant Activation</h3>
            <p className="text-gray-700 mt-2">
              Get started with our IPTV service in just a few clicks.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

// Icons (no changes to icons)
function VideoIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="m16 13 5.223 3.482a.5.5 0 0 0 .777-.416V7.87a.5.5 0 0 0-.752-.432L16 10.5" />
      <rect x="2" y="6" width="14" height="12" rx="2" />
    </svg>
  );
}

function WifiIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M12 20h.01" />
      <path d="M2 8.82a15 15 0 0 1 20 0" />
      <path d="M5 12.859a10 10 0 0 1 14 0" />
      <path d="M8.5 16.429a5 5 0 0 1 7 0" />
    </svg>
  );
}

function PowerIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M12 2v10" />
      <path d="M18.4 6.6a9 9 0 1 1-12.77.04" />
    </svg>
  );
}

function CloudLightningIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M6 16.326A7 7 0 1 1 15.71 8h1.79a4.5 4.5 0 0 1 .5 8.973" />
      <path d="m13 12-3 5h4l-3 5" />
    </svg>
  );
}

export default KeyFeaturesSection;
