import React from 'react';
import { Facebook, Twitter, Instagram, Mail, Phone } from 'lucide-react';
import { Link } from 'react-router-dom'; // If you're using react-router for navigation

const Footer = () => {
  return (
    <footer className="bg-gray-100 text-gray-800 py-12">
      <div className="container mx-auto px-4 md:px-6">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {/* Company Logo and Description */}
          <div>
            <h2 className="text-2xl font-bold mb-4 text-[rgb(255,107,107)]">Flix Sphere</h2>
            <p className="text-gray-600">
              Flix Sphere offers the best IPTV service with a vast selection of channels and VOD at unbeatable prices and quality.
            </p>
          </div>

          {/* Quick Links */}
          <div>
            <h3 className="text-xl font-semibold mb-4">Quick Links</h3>
            <div className="grid grid-cols-2 gap-4">
              <ul className="space-y-2">
                <li>
                  <Link to="/pricing" className="hover:text-[rgb(255,107,107)] transition duration-200">
                    Pricing
                  </Link>
                </li>
                <li>
                  <Link to="/installation" className="hover:text-[rgb(255,107,107)] transition duration-200">
                    Installation
                  </Link>
                </li>
                <li>
                  <Link to="/contact" className="hover:text-[rgb(255,107,107)] transition duration-200">
                    Contact
                  </Link>
                </li>
              </ul>
              <ul className="space-y-2">
                <li>
                  <Link to="/terms" className="hover:text-[rgb(255,107,107)] transition duration-200">
                    Terms of Service
                  </Link>
                </li>
                <li>
                  <Link to="/privacy" className="hover:text-[rgb(255,107,107)] transition duration-200">
                    Privacy Policy
                  </Link>
                </li>
                <li>
                  <Link to="/faq" className="hover:text-[rgb(255,107,107)] transition duration-200">
                    FAQ
                  </Link>
                </li>
              </ul>
            </div>
          </div>

          {/* Contact Information */}
          <div>
            <h3 className="text-xl font-semibold mb-4">Get in Touch</h3>
            <p className="flex items-center">
              <Mail className="w-5 h-5 mr-2 text-[rgb(255,107,107)]" />
              <a href="mailto:support@FlixSphere.com" className="hover:text-[rgb(255,107,107)] transition duration-200">
                support@FlixSphere.com
              </a>
            </p>
            <p className="flex items-center mt-2">
              <Phone className="w-5 h-5 mr-2 text-[rgb(255,107,107)]" />
              <a href="tel:+447898002230" className="hover:text-[rgb(255,107,107)] transition duration-200">
                +44 789 800 2230
              </a>
            </p>
            {/* Social Media Icons */}
            <div className="flex space-x-4 mt-4">
              <a href="https://www.facebook.com/FlixSphere" target="_blank" rel="noopener noreferrer">
                <Facebook className="w-6 h-6 text-gray-600 hover:text-[rgb(255,107,107)] transition duration-200" />
              </a>
              <a href="https://twitter.com/FlixSphere" target="_blank" rel="noopener noreferrer">
                <Twitter className="w-6 h-6 text-gray-600 hover:text-[rgb(255,107,107)] transition duration-200" />
              </a>
              <a href="https://www.instagram.com/FlixSphere" target="_blank" rel="noopener noreferrer">
                <Instagram className="w-6 h-6 text-gray-600 hover:text-[rgb(255,107,107)] transition duration-200" />
              </a>
            </div>
          </div>
        </div>

        {/* Footer Bottom */}
        <div className="mt-12 border-t border-gray-300 pt-4 text-center">
          <p className="text-sm text-gray-600">
            © {new Date().getFullYear()} Flix Sphere. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
