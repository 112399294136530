import React from 'react';

const IPTVSmartersAndroidiOSGuide = () => {
  return (
    <div className="container mx-auto px-6 py-16 mt-20">
      <h2 className="text-3xl font-extrabold mb-8 text-[#ff6b6b]">
        IPTV Smarters Installation Guide for Android / iOS
      </h2>
      <p className="mb-6">
        To install IPTV Smarters on your Android or iOS device, follow these steps:
      </p>

      <ol className="list-decimal list-inside space-y-4">
        <li>Open the Google Play Store (Android) or App Store (iOS) on your device.</li>
        <li>In the search bar, type "IPTV Smarters" and find the official app.</li>
        <li>Click the <strong>Install</strong> button to download and install the app.</li>
        <li>Once installed, open the app.</li>
        <li>On the first screen, accept the Terms and Conditions to proceed.</li>
        <li>Log in with your IPTV credentials, including the <strong>Username</strong>, <strong>Password</strong>, and <strong>Portal URL</strong> that have been sent to your email by FlixSphere.</li>
        <li>Once logged in, you can begin watching your IPTV channels and VOD (Video On Demand) content immediately!</li>
      </ol>

      <div className="mt-8 mb-6 border-t-2 pt-6">
        <p>
          If you need further assistance, feel free to contact us via email at <strong>support@nexatvflix.com</strong> or through WhatsApp for a faster response.
        </p>
      </div>

      <div className="text-center mt-8">
        <a
          href="https://wa.me/447898002230?text=Hi%2C%20I%20need%20help%20setting%20up%20IPTV%20Smarters%20on%20my%20Android%20or%20iOS%20device."
          target="_blank"
          rel="noopener noreferrer"
          className="inline-block bg-green-500 text-white font-semibold py-3 px-8 rounded-md hover:bg-green-600 transition-colors"
        >
          Contact us on WhatsApp
        </a>
      </div>
    </div>
  );
};

export default IPTVSmartersAndroidiOSGuide;
