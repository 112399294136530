import React, { useState, useRef, useEffect } from 'react';
import { ChevronDown } from 'lucide-react';

const Select = ({ children, value, onValueChange, placeholder = "Select an option" }) => {
  const [isOpen, setIsOpen] = useState(false);
  const selectRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (selectRef.current && !selectRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="relative" ref={selectRef}>
      <div
        className="flex items-center justify-between w-full p-3 border border-gray-300 rounded cursor-pointer bg-white"
        onClick={() => setIsOpen(!isOpen)}
      >
        <span className={`${value ? '' : 'text-gray-400'}`}>
          {value || placeholder}
        </span>
        <ChevronDown className={`transition-transform duration-200 ${isOpen ? 'transform rotate-180' : ''}`} />
      </div>
      {isOpen && (
        <div className="absolute z-10 w-full mt-1 bg-white border border-gray-300 rounded shadow-lg">
          {React.Children.map(children, (child) =>
            React.cloneElement(child, {
              onSelect: () => {
                onValueChange(child.props.value);
                setIsOpen(false);
              },
            })
          )}
        </div>
      )}
    </div>
  );
};

Select.Item = ({ children, value, onSelect }) => (
  <div
    className="p-3 cursor-pointer hover:bg-gray-100"
    onClick={() => onSelect(value)}
  >
    {children}
  </div>
);

export default Select;