import React from 'react';
import HeroSection from '../components/HeroSection';
import KeyFeaturesSection from '../components/KeyFeaturesSection';
import PricingSection from '../components/PricingSection';
import CustomerTestimonials from '../components/CustomerTestimonials';
import HowItWorks from '../components/HowItWorks';
import ChannelPreview from '../components/ChannelPreview';
import DeviceCompatibilitySection from '../components/DeviceCompatibilitySection';
import FAQSection from '../components/FAQSection';
import TrustSecuritySection from '../components/TrustSecuritySection';

const Home = ({ onPlanSelect }) => {
  return (
    <div style={{ paddingTop: '50px' }}> {/* Add paddingTop to avoid content being overlapped by header */}
      <HeroSection onPlanSelect={onPlanSelect} />
      <KeyFeaturesSection />
      <PricingSection onPlanSelect={onPlanSelect} />
      <CustomerTestimonials />
      <HowItWorks />
      <ChannelPreview />
      <DeviceCompatibilitySection />
      <FAQSection />
      <TrustSecuritySection />
    </div>
  );
};

export default Home;
