// src/components/ui/input.js
import React from 'react';

const Input = ({ className, ...props }) => {
  return (
    <input
      type="text"
      className={`p-2 border rounded-md ${className}`}
      {...props}
    />
  );
};

export default Input;
