import React from 'react';

const WindowsGuide = () => {
  return (
    <div className="container mx-auto px-6 py-16 mt-20">
      <h2 className="text-3xl font-extrabold mb-8 text-[#ff6b6b]">
        IPTV Smarters Installation Guide for Windows Devices
      </h2>
      <p className="mb-6">
        To install IPTV Smarters on your Windows device, follow these steps:
      </p>
      <ol className="list-decimal list-inside space-y-4">
        <li>Open your web browser on your Windows device.</li>
        <li>Go to the official IPTV Smarters website.</li>
        <li>Download the IPTV Smarters setup file for Windows.</li>
        <li>Once the download is complete, open the file and follow the on-screen instructions to install the app.</li>
        <li>After installation, launch the IPTV Smarters app.</li>
        <li>Log in using your IPTV credentials, which will be sent to you via email after subscription.</li>
        <li>Start enjoying IPTV on your Windows device!</li>
      </ol>
      <div className="mt-10 text-center">
        <p className="text-lg mb-6">
          If you need further assistance, feel free to contact us via email at <strong>support@FlixSphere.com</strong> or through WhatsApp for a faster response.
        </p>
        <a
          href="https://wa.me/447898002230?text=Hi%20I%20need%20help%20setting%20up%20IPTV%20service%20on%20my%20Windows%20device"
          target="_blank"
          rel="noopener noreferrer"
          className="inline-flex items-center justify-center px-8 py-3 bg-green-500 text-white font-semibold rounded-md shadow-md hover:bg-green-600 transition-colors duration-200"
        >
          Contact us on WhatsApp
        </a>
      </div>
    </div>
  );
};

export default WindowsGuide;
