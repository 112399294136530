import React from 'react';

const LGSmartTVGuide = () => {
  return (
    <div className="container mx-auto px-6 py-16 mt-20">
      <h2 className="text-3xl font-extrabold mb-8 text-[#ff6b6b]">IBO Player Installation Guide for LG Smart TV</h2>
      <p className="mb-6">
        To install IBO Player on your LG Smart TV, follow these steps:
      </p>
      <ol className="list-decimal list-inside space-y-4">
        <li>Turn on your LG Smart TV and ensure it is connected to the internet.</li>
        <li>On your remote, press the **Home/Smart** button to open the **LG Content Store**.</li>
        <li>In the search bar, type **IBO Player** and select the app from the results.</li>
        <li>Download and install the IBO Player app from the store.</li>
        <li>Once installed, open the IBO Player app. You will see a **Device ID** and **Device Key** on the screen.</li>
        <li>Note these down, as you will need them to activate the app.</li>
        <li>Go to the **IBO Player Activation** website: <a href="https://iboiptv.com" target="_blank" rel="noopener noreferrer" className="text-blue-500 underline">iboiptv.com</a>.</li>
        <li>Enter the **Device ID** and **Device Key** to activate the app on your TV.</li>
        <li>Upload your IPTV playlist (M3U or Xtream Codes API) via the website.</li>
        <li>Return to the IBO Player app on your LG TV, and enjoy your IPTV service!</li>
      </ol>

      <div className="mt-8 mb-6 border-t-2 pt-6">
        <p>
          If you need further assistance, feel free to contact us via email at <strong>support@FlixSphere.com</strong> or through WhatsApp for a faster response.
        </p>
      </div>

      <div className="text-center mt-8">
        <a
          href="https://wa.me/447898002230?text=Hi%20I%20need%20help%20setting%20up%20IPTV%20on%20my%20LG%20Smart%20TV%20with%20IBO%20Player."
          target="_blank"
          rel="noopener noreferrer"
          className="inline-block bg-green-500 text-white font-semibold py-3 px-8 rounded-md hover:bg-green-600 transition-colors"
        >
          Contact us on WhatsApp
        </a>
      </div>
    </div>
  );
};

export default LGSmartTVGuide;
