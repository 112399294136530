import React from 'react';

const AndroidGuide = () => {
  return (
    <div className="container mx-auto px-6 py-16 mt-20">
      {/* Adjusted spacing to prevent overlap with the header */}
      <h2 className="text-3xl font-extrabold mb-8 text-[#ff6b6b]">
        PRO MAX Installation Guide for Android
      </h2>
      <p className="mb-6">
        To install PRO MAX on your Android device, follow these steps:
      </p>
      <ol className="list-decimal list-inside space-y-4">
        <li>Go to the TV home screen, then "Find" → "Search" → Search for "Downloader" and download it.</li>
        <li>Enable Apps from Unknown Sources in **Settings** → **My Fire TV** → **Developer Options**.</li>
        <li>Open the Downloader app and enter the CODE: `921397` and click "Go."</li>
        <li>Once the APK is downloaded, select "Install" to install PRO MAX.</li>
        <li>Open PRO MAX, agree to terms and log in with the IPTV credentials provided via **email** or **WhatsApp**.</li>
        <li>Start streaming and enjoy IPTV on your TV!</li>
      </ol>

      {/* Adding some space between the steps and the contact section */}
      <div className="mt-12 border-t-2 border-gray-200 pt-6">
        <p className="mb-6 text-lg">
          If you need further assistance, feel free to contact us via email at{' '}
          <a href="mailto:support@FlixSphere.com" className="text-blue-500 underline">
            support@Flix Sphere.com
          </a>{' '}
          or through WhatsApp for a faster response.
        </p>

        <div className="flex justify-center">
          <a
            href="https://wa.me/447898002230?text=Hi%2C%20I%20need%20help%20setting%20up%20IPTV%20service%20on%20my%20Android%20device."
            target="_blank"
            rel="noopener noreferrer"
            className="mt-4 inline-block px-6 py-3 bg-green-500 text-white font-semibold rounded-lg shadow-md hover:bg-green-600 transition-colors duration-200"
          >
            Contact us on WhatsApp
          </a>
        </div>
      </div>
    </div>
  );
};

export default AndroidGuide;
