import React from 'react';

const EnigmaGuide = () => {
  return (
    <div className="container mx-auto px-6 py-16 mt-20">
      <h2 className="text-3xl font-extrabold mb-8 text-[#ff6b6b]">
        IPTV Installation Guide for Enigma / Zgemma Devices
      </h2>
      <p className="mb-6">
        To set up IPTV on Enigma or Zgemma devices, follow these steps:
      </p>
      <ol className="list-decimal list-inside space-y-4">
        <li>Download the **Putty** application on your computer and install it. This will allow you to connect to your Enigma or Zgemma device remotely.</li>
        <li>Obtain your device's **IP address**. You can usually find it in the settings menu under network information on your device.</li>
        <li>Open Putty, enter the device's IP address, and set the connection type to **Telnet**.</li>
        <li>Log in using the following credentials: <br />
        **Username**: root <br />
        **Password**: (usually blank, unless set otherwise).</li>
        <li>Enter the IPTV script provided via email by pasting it into the Putty command line.</li>
        <li>After running the script, reboot your device for the changes to take effect.</li>
        <li>Your IPTV channels will now be available under the **TV** or **Bouquet** list on your device.</li>
      </ol>
      <div className="mt-10 text-center">
        <p className="text-lg mb-6">
          If you need further assistance, feel free to contact us via email at <strong>support@FlixSphere.com</strong> or through WhatsApp for a faster response.
        </p>
        <a
          href="https://wa.me/447898002230?text=Hi%20I%20need%20help%20setting%20up%20IPTV%20service%20on%20my%20Enigma/Zgemma%20device"
          target="_blank"
          rel="noopener noreferrer"
          className="inline-flex items-center justify-center px-8 py-3 bg-green-500 text-white font-semibold rounded-md shadow-md hover:bg-green-600 transition-colors duration-200"
        >
          Contact us on WhatsApp
        </a>
      </div>
    </div>
  );
};

export default EnigmaGuide;
