import React from 'react';
import { CheckCircle, Clock, Tv, PlayCircle } from 'lucide-react'; // Import icons for each step

const HowItWorks = () => {
  const steps = [
    {
      title: 'Choose a Plan',
      icon: <CheckCircle className="w-12 h-12 text-black" />, // Icon color set to dark black
      description: 'Select the perfect subscription plan that fits your entertainment needs and budget.',
    },
    {
      title: 'Receive Your Subscription',
      icon: <Clock className="w-12 h-12 text-black" />,
      description: 'Delivery is usually instant, but in some cases, it may take between 5 minutes to 2 hours.',
    },
    {
      title: 'Install Your Service',
      icon: <Tv className="w-12 h-12 text-black" />,
      description: 'Easily install the IPTV app on any device, and follow our simple setup guide.',
    },
    {
      title: 'Start Watching Instantly',
      icon: <PlayCircle className="w-12 h-12 text-black" />,
      description: 'Enjoy instant access to thousands of live channels and on-demand content.',
    },
  ];

  return (
    <section className="w-full py-16 bg-white text-foreground">
      <div className="container mx-auto px-4 md:px-6">
        {/* Section Header */}
        <div className="flex flex-col items-center justify-center space-y-4 text-center">
          <h2 className="text-4xl font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-[#ff6b6b] to-[#ffa500] mb-6">
            How It Works
          </h2>
          <p className="max-w-[900px] text-lg text-gray-500 md:text-xl lg:text-2xl leading-relaxed">
            Get started in just 4 easy steps and start enjoying your favorite channels.
          </p>
        </div>

        {/* 4-Step Process */}
        <div className="grid gap-8 mt-12 md:grid-cols-1 lg:grid-cols-4">
          {steps.map((step, index) => (
            <div key={index} className="flex flex-col items-center text-center p-6 border border-gray-200 rounded-lg shadow-lg">
              {/* Step Number and Icon */}
              <div className="flex items-center justify-center mb-4">
                <span className="text-2xl font-bold text-black mr-2">{index + 1} -</span>
                {step.icon}
              </div>

              {/* Step Title */}
              <h3 className="text-2xl font-semibold text-black mb-4">{step.title}</h3>
              
              {/* Step Description */}
              <p className="text-gray-500 text-lg">{step.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default HowItWorks;
