import React, { useState } from 'react';
import { ChevronDown, ChevronUp } from 'lucide-react';

const FAQSection = () => {
  const [activeIndex, setActiveIndex] = useState(null);
  const [searchTerm, setSearchTerm] = useState(''); // State for the search term

  const faqs = [
    {
      question: "Is it compatible with my device?",
      answer: "Flix Sphere is accessible on almost all devices, including Smart TVs (Samsung, Sony, LG), Android devices, Apple TV, iPhone, STB emulator app, Fire Stick, and more. For any questions, our agent will contact you for comprehensive assistance.",
    },
    {
      question: "How to install my IPTV subscription?",
      answer: "After payment, we will send you an installation email with your access information and installation instructions for all devices and applications (Smart IPTV, Android Box, PC, Mac, Smartphone, Apple TV, etc.).",
    },
    {
      question: "When will my IPTV subscription take effect?",
      answer: "The subscription takes effect from the moment you receive your activation credentials via email. You will have access immediately for the full duration of your subscription.",
    },
    {
      question: "How will I receive my subscription?",
      answer: "After payment, you will receive an email within an hour containing your login credentials and access information.",
    },
    {
      question: "Will you renew my subscription automatically?",
      answer: "No, we will contact you by email or WhatsApp to renew your subscription when it is about to expire.",
    },
    {
      question: "Will Flix Sphere work in my country?",
      answer: "Yes, Flix Sphere works worldwide. Just ensure your internet speed is sufficient for smooth streaming.",
    },
    {
      question: "What are the advantages of Flix Sphere?",
      answer: "We offer the largest selection of channels and VOD at unbeatable prices and quality, including over 19,000 live channels and 4K content.",
    },
    {
      question: "What are the payment options?",
      answer: "You can pay by Card, PayPal, or Cryptocurrency. Payments are made through a secure gateway with no hidden fees.",
    },
    {
      question: "Do you have any questions or suggestions?",
      answer: "Feel free to contact us! We would love to hear from you and develop Flix Sphere's services with your input.",
    },
    {
      question: "How to get support if I face an issue?",
      answer: "We offer 24/7 support via live chat. Our team is ready to assist you with any questions or issues you may encounter.",
    },
  ];

  const handleToggle = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  // Filter FAQs based on the search term
  const filteredFaqs = faqs.filter(faq =>
    faq.question.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <section className="w-full py-16 bg-white text-gray-800">
      <div className="container mx-auto px-4 md:px-6">
        <h2 className="text-4xl font-extrabold text-center mb-12 text-transparent bg-clip-text bg-gradient-to-r from-[#ff6b6b] to-[#ffa500]">
          Frequently Asked Questions
        </h2>

        {/* Search Bar */}
        <div className="mb-8">
          <input
            type="text"
            placeholder="Search for a question..."
            className="w-full p-3 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-[#ff6b6b]"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>

        {/* FAQ List */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {filteredFaqs.map((faq, index) => (
            <div
              key={index}
              className="p-6 bg-gray-100 rounded-lg shadow-lg transition duration-300 ease-in-out transform hover:shadow-2xl"
            >
              <button
                onClick={() => handleToggle(index)}
                className="w-full flex justify-between items-center text-left focus:outline-none"
              >
                <span className="text-lg font-semibold text-black">{faq.question}</span>
                {activeIndex === index ? (
                  <ChevronUp className="w-6 h-6 text-[rgb(255,107,107)]" />
                ) : (
                  <ChevronDown className="w-6 h-6 text-[rgb(255,107,107)]" />
                )}
              </button>
              {activeIndex === index && (
                <p
                  className="mt-4 text-gray-600 transition-all duration-300 ease-in-out"
                  style={{ maxHeight: activeIndex === index ? '1000px' : '0px', overflow: 'hidden' }}
                >
                  {faq.answer}
                </p>
              )}
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default FAQSection;
