import React from 'react';

const AppleGuide = () => {
  return (
    <div className="container mx-auto px-6 py-16 mt-20">
      {/* Adjusted spacing to prevent overlap with the header */}
      <h2 className="text-3xl font-extrabold mb-8 text-[#ff6b6b]">
        IPTV Smarters Installation Guide for Apple / iOS
      </h2>
      <p className="mb-6">
        To install IPTV Smarters on your Apple device, follow these steps:
      </p>
      <ol className="list-decimal list-inside space-y-4">
        <li>Go to the App Store on your Apple device.</li>
        <li>Search for "IPTV Smarters" and click on the official app.</li>
        <li>Download and install the app on your device.</li>
        <li>
          Once installed, open the app and log in with your IPTV credentials provided by email.
        </li>
        <li>Start enjoying IPTV on your Apple device!</li>
      </ol>

      {/* Adding some space between the steps and the contact section */}
      <div className="mt-12 border-t-2 border-gray-200 pt-6">
        <p className="mb-6 text-lg">
          If you need further assistance, feel free to contact us via email at{' '}
          <a href="mailto:support@FlixSphere.com" className="text-blue-500 underline">
            support@Flix Sphere.com
          </a>{' '}
          or through WhatsApp for a faster response.
        </p>

        <div className="flex justify-center">
          <a
            href="https://wa.me/447898002230?text=Hi%2C%20I%20need%20help%20setting%20up%20IPTV%20service%20on%20my%20Apple%20device."
            target="_blank"
            rel="noopener noreferrer"
            className="mt-4 inline-block px-6 py-3 bg-green-500 text-white font-semibold rounded-lg shadow-md hover:bg-green-600 transition-colors duration-200"
          >
            Contact us on WhatsApp
          </a>
        </div>
      </div>
    </div>
  );
};

export default AppleGuide;
