import React from 'react';
import { Star } from 'lucide-react'; // Import star icon for ratings

const testimonials = [
  {
    name: 'John Doe',
    quote: "This is the best IPTV service I've ever used! The quality is amazing, and the customer support is top-notch.",
    rating: 5,
  },
  {
    name: 'Jane Smith',
    quote: "The subscription plans are very affordable, and I love the variety of channels available. Highly recommend!",
    rating: 4,
  },
  {
    name: 'Mark Johnson',
    quote: "I've tried many streaming services, but this one stands out. The seamless streaming and premium sports packages are a game changer.",
    rating: 5,
  },
];

const mediaMentions = [
  {
    quote: "The best IPTV service available on the market today.",
    source: 'MediaPulse',
  },
  {
    quote: "A leader in IPTV technology with exceptional customer support.",
    source: 'StreamPro',
  },
];

const CustomerTestimonials = () => {
  return (
    <section className="w-full py-16 bg-gray-100 text-foreground">
      <div className="container mx-auto px-4 md:px-6">
        {/* Section Header */}
        <div className="flex flex-col items-center justify-center space-y-4 text-center">
          <h2 className="text-4xl font-extrabold tracking-tight text-center mb-6 text-transparent bg-clip-text bg-gradient-to-r from-[#ff6b6b] to-[#ffa500]">
            Customer Testimonials
          </h2>
          <p className="max-w-[900px] text-lg text-gray-500 md:text-xl lg:text-2xl leading-relaxed">
            See what our customers have to say about our services.
          </p>
        </div>

        {/* Testimonials Grid */}
        <div className="grid gap-8 mt-12 md:grid-cols-1 lg:grid-cols-3">
          {testimonials.map((testimonial, index) => (
            <div key={index} className="p-6 border border-gray-300 rounded-lg bg-white shadow-md">
              <p className="text-lg text-gray-600 italic mb-4">"{testimonial.quote}"</p>
              <div className="flex items-center">
                {[...Array(testimonial.rating)].map((_, i) => (
                  <Star key={i} className="w-5 h-5 text-yellow-500" />
                ))}
              </div>
              <p className="mt-4 font-semibold text-gray-800">{testimonial.name}</p>
            </div>
          ))}
        </div>

        {/* Optional: Industry Mentions */}
        <div className="mt-12">
          <h3 className="text-3xl font-bold text-center mb-8 text-transparent bg-clip-text bg-gradient-to-r from-[#ff6b6b] to-[#ffa500]">As Seen In</h3>
          <div className="flex justify-center items-center space-x-8">
            {mediaMentions.map((media, index) => (
              <div key={index} className="text-center">
                <p className="text-gray-600 italic">"{media.quote}"</p>
                <p className="font-semibold text-gray-800 mt-2">{media.source}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default CustomerTestimonials;
