import React from 'react';
import { Lock, CreditCard } from 'lucide-react'; // Icons for badges

const TrustSecuritySection = () => {
  return (
    <section className="w-full py-16 bg-white text-gray-800">
      <div className="container mx-auto px-4 md:px-6">
        {/* Section Title */}
        <h2 className="text-4xl font-extrabold text-center mb-12 text-transparent bg-clip-text bg-gradient-to-r from-[#ff6b6b] to-[#ffa500]">
          Trust & Security
        </h2>

        <p className="text-center max-w-3xl mx-auto text-lg mb-12">
          At Flix Sphere, we prioritize the safety and security of your data. Our service is equipped with the latest security measures to ensure a reliable and secure experience.
        </p>

        {/* Security Badges & Payment Logos */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 text-center">
          {/* Secure Payments */}
          <div className="flex flex-col items-center p-6 bg-gray-100 rounded-lg shadow-lg">
            <Lock className="w-12 h-12 text-[rgb(255,107,107)] mb-4" /> 
            <p className="text-gray-600 mt-2">
              Payments are processed securely using trusted providers.
            </p>
          </div>

          {/* Stripe Logo */}
          <div className="flex flex-col items-center p-6 bg-gray-100 rounded-lg shadow-lg">
            <img
              src="https://res.cloudinary.com/benejma/image/upload/v1726661848/d69a52f45ba68b83254c08d662f4b863_yrsvre.png"
              alt="Stripe Logo"
              className="w-24 h-12 mb-4 object-contain"
            />
            <p className="text-gray-600 mt-2">
              Enjoy secure and hassle-free payments through Stripe.
            </p>
          </div>

          {/* PayPal Logo */}
          <div className="flex flex-col items-center p-6 bg-gray-100 rounded-lg shadow-lg">
            <img
              src="https://upload.wikimedia.org/wikipedia/commons/b/b5/PayPal.svg"
              alt="PayPal Logo"
              className="w-24 h-12 mb-4 object-contain"
            />
            <p className="text-gray-600 mt-2">
              We support PayPal for fast and secure payments.
            </p>
          </div>

          {/* Verified Service */}
          <div className="flex flex-col items-center p-6 bg-gray-100 rounded-lg shadow-lg">
            <CreditCard className="w-12 h-12 text-[rgb(255,107,107)] mb-4" />
            <p className="text-gray-600 mt-2">
              Trusted by thousands of satisfied customers worldwide.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TrustSecuritySection;
