import React from 'react';
import { Link } from 'react-router-dom';
import { blogsData } from './BlogsData';

const Blogs = () => {
  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-100 to-white py-[100px] px-[50px] sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto">
        <h2 className="text-4xl font-extrabold text-center mb-12 text-gray-900">
          Nexustvflix <span className="text-transparent bg-clip-text bg-gradient-to-r from-[#ff6b6b] to-[#ffa500]">Knowledge Hub</span>
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {blogsData.map((blog) => (
            <div key={blog.id} className="bg-white rounded-xl shadow-lg overflow-hidden transform transition duration-300 hover:scale-105">
              <div className="p-6">
                <div className="uppercase tracking-wide text-sm text-[#ff6b6b] font-semibold mb-2">{blog.date}</div>
                <h3 className="text-2xl font-bold text-gray-900 mb-3 line-clamp-2">{blog.title}</h3>
                <p className="text-gray-600 mb-4 line-clamp-3">{blog.excerpt}</p>
                <Link 
                  to={`/blog/${blog.id}`}
                  className="inline-block px-4 py-2 bg-gradient-to-r from-[#ff6b6b] to-[#ffa500] text-white font-semibold rounded-lg shadow-md hover:from-[#ff8c8c] hover:to-[#ffb732] transition duration-300 ease-in-out"
                >
                  Read More
                </Link>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Blogs;