import React from 'react';
import { Link } from 'react-router-dom';

const Installation = () => {
  const installationGuides = [
    { name: 'Apple / iOS', link: '/installation/apple' },
    { name: 'Android', link: '/installation/android' },
    { name: 'Samsung TV', link: '/installation/samsung' },
    { name: 'Firestick', link: '/installation/firestick' },
    { name: 'LG Smart TV', link: '/installation/lgsmarttv' },
    { name: 'MAG Box', link: '/installation/magbox' },
    { name: 'IPTV Smarters', link: '/installation/iptvsmarters' },
    { name: 'Windows Device', link: '/installation/windowsdevice' },
    { name: 'Enigma / Zgemma', link: '/installation/enigma' },
  ];

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col items-center justify-center px-4 sm:px-6 lg:px-8 py-12">
      <h1 className="text-4xl font-extrabold mb-8 text-center text-transparent bg-clip-text bg-gradient-to-r from-[#ff6b6b] to-[#ffa500]">
        Installation Guides
      </h1>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 w-full max-w-6xl">
        {installationGuides.map((guide) => (
          <div
            key={guide.name}
            className="bg-white p-6 rounded-lg shadow-lg flex flex-col items-center justify-between text-center"
          >
            <h2 className="text-lg font-semibold mb-4">{guide.name}</h2>
            <Link
              to={guide.link}
              className="mt-auto inline-block px-6 py-2 bg-gradient-to-r from-[#ff6b6b] to-[#ffa500] text-white font-semibold rounded-lg shadow-md hover:from-[#ff4d4d] hover:to-[#ff9100] transition-colors duration-200"
            >
              View Guide
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Installation;
