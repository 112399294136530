import React, { useState } from 'react';
import { Tv, Smartphone, Tablet, Laptop, Monitor, Cast } from 'lucide-react'; 

const DeviceCompatibility = () => {
  // State to track which device is being hovered or clicked
  const [activeDevice, setActiveDevice] = useState(null);

  // Device data with hover descriptions
  const devices = [
    {
      name: 'Smart TVs',
      icon: <Tv className="w-8 h-8 text-white" />,
      description: 'Stream IPTV directly on your Smart TV using our dedicated app or via the built-in browser.',
    },
    {
      name: 'Laptops',
      icon: <Laptop className="w-8 h-8 text-white" />,
      description: 'Access IPTV services on your laptop through any web browser for a seamless experience.',
    },
    {
      name: 'Smartphones',
      icon: <Smartphone className="w-8 h-8 text-white" />,
      description: 'Watch your favorite channels on the go with our mobile-friendly IPTV app.',
    },
    {
      name: 'Tablets',
      icon: <Tablet className="w-8 h-8 text-white" />,
      description: 'Enjoy IPTV on a larger screen with our tablet-optimized interface.',
    },
    {
      name: 'Desktops',
      icon: <Monitor className="w-8 h-8 text-white" />,
      description: 'Stream directly on your desktop PC with high-definition quality.',
    },
    {
      name: 'Streaming Devices',
      icon: <Cast className="w-8 h-8 text-white" />,
      description: 'Use Chromecast, Roku, or Firestick to stream IPTV on any screen.',
    },
  ];

  return (
    <section className="w-full py-16 bg-gray-100 text-gray-800">
      <div className="container mx-auto px-4 md:px-6">
        {/* Section Title */}
        <h2 className="text-4xl font-extrabold text-center mb-8 text-transparent bg-clip-text bg-gradient-to-r from-[#ff6b6b] to-[#ffa500]">
          Device Compatibility
        </h2>

        <p className="text-center max-w-3xl mx-auto text-lg mb-12">
          Our IPTV service is designed to work seamlessly across a variety of devices. Stream your favorite channels from the comfort of your home or on the go. Enjoy unlimited access on Smart TVs, laptops, smartphones, tablets, and more.
        </p>

        {/* Device Icons in a Single Row */}
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-8 text-center">
          {devices.map((device, index) => (
            <div
              key={index}
              className="flex flex-col items-center group"
              onMouseEnter={() => setActiveDevice(index)}
              onMouseLeave={() => setActiveDevice(null)}
              onClick={() => setActiveDevice(activeDevice === index ? null : index)} // Toggle on click
            >
              {/* Animated Icon */}
              <div className="w-16 h-16 flex items-center justify-center mb-4 rounded-full bg-gradient-to-r from-orange-400 to-yellow-500 transition-transform transform group-hover:scale-110">
                {device.icon}
              </div>
              <h3 className="text-xl font-semibold">{device.name}</h3>

              {/* Expandable Description */}
              {activeDevice === index && (
                <p className="mt-4 text-gray-600 text-sm transition-opacity opacity-100">
                  {device.description}
                </p>
              )}
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default DeviceCompatibility;
