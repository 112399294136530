import React, { useEffect, useState } from 'react';
import Input from "../components/ui/input"; // Adjust the path based on your project structure
import Button from "../components/ui/button"; // Adjust the path based on your project structure
import { ChevronLeft, ChevronRight } from "lucide-react";

const ChannelPreview = () => {
  const [channels, setChannels] = useState([]);
  const [groups, setGroups] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState(''); // The currently selected group
  const [searchTerm, setSearchTerm] = useState(''); // State to store the search term
  const [currentPage, setCurrentPage] = useState(1);
  const channelsPerPage = 8;

  // Fetch the channels JSON on component load
  const fetchChannels = async () => {
    try {
      const response = await fetch('/Data/channels.json'); // Adjust this path to your channels.json file
      const data = await response.json();

      const uniqueGroups = [...new Set(data.map(channel => channel.group))];

      setChannels(data);
      setGroups(uniqueGroups);
    } catch (error) {
      console.error('Error fetching channels:', error);
    }
  };

  // Get filtered channels based on the selected group and search term
  const filteredChannels = channels.filter(channel =>
    (selectedGroup ? channel.group === selectedGroup : true) &&
    channel.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Pagination logic
  const indexOfLastChannel = currentPage * channelsPerPage;
  const indexOfFirstChannel = indexOfLastChannel - channelsPerPage;
  const currentChannels = filteredChannels.slice(indexOfFirstChannel, indexOfLastChannel);

  useEffect(() => {
    fetchChannels();
  }, []);

  // Reset pagination when selectedGroup or searchTerm changes
  useEffect(() => {
    setCurrentPage(1);
  }, [selectedGroup, searchTerm]);

  return (
    <section className="w-full py-16 bg-white text-foreground">
      <div className="container mx-auto px-4 md:px-6">
        {/* Main Title */}
        <h1 className="text-4xl font-extrabold text-center mb-12 text-transparent bg-clip-text bg-gradient-to-r from-[#ff6b6b] to-[#ffa500]">
          Explore Our Channels
        </h1>

        <div className="flex flex-col lg:flex-row gap-8">
          {/* Left Side: Some Text */}
          <div className="w-full lg:w-1/3 bg-[#ff6b6b] p-6 rounded-lg shadow-lg">
            <h2 className="text-2xl font-semibold mb-4 text-white">About Our Channels</h2>
            <p className="text-white mb-4">
              Discover a vast selection of channels tailored to suit your entertainment needs. Use the search and filter options to find channels quickly.
            </p>
          </div>

          {/* Right Side: Channels Listing */}
          <div className="w-full lg:w-2/3">
            {/* Group Selection Dropdown */}
            <div className="mb-4">
              <select
                className="w-full p-2 rounded bg-gray-100 text-black mb-4"
                value={selectedGroup}
                onChange={(e) => setSelectedGroup(e.target.value)}
              >
                <option value="">All Groups</option>
                {groups.map((group, index) => (
                  <option key={index} value={group}>
                    {group}
                  </option>
                ))}
              </select>

              {/* Search Input */}
              <Input
                className="w-full mb-4"
                placeholder="Search Channels"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>

            {/* Channel Listing */}
            <div className="bg-white p-4 rounded-lg shadow-lg">
              {currentChannels.length > 0 ? (
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
                  {currentChannels.map((channel, index) => (
                    <div key={index} className="flex items-center p-2 border border-gray-200 rounded-lg">
                      <img src={channel.logo} alt={`${channel.title} logo`} className="w-10 h-10 mr-2" />
                      <span className="text-gray-800 font-medium truncate">{channel.title}</span>
                    </div>
                  ))}
                </div>
              ) : (
                <div className="text-center text-red-500 mt-4">
                  No channels found for this group.
                </div>
              )}
            </div>

            {/* Pagination Controls */}
            {filteredChannels.length > channelsPerPage && (
              <div className="flex justify-between mt-6">
                <Button
                  onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
                  disabled={currentPage === 1}
                  className="bg-[#ff6b6b] text-white p-2 rounded hover:bg-[#ff4d4d]"
                >
                  <ChevronLeft className="w-4 h-4" />
                </Button>
                <div className="text-gray-700">Page {currentPage} of {Math.ceil(filteredChannels.length / channelsPerPage)}</div>
                <Button
                  onClick={() => setCurrentPage(prev => prev + 1)}
                  disabled={indexOfLastChannel >= filteredChannels.length}
                  className="bg-[#ff6b6b] text-white p-2 rounded hover:bg-[#ff4d4d]"
                >
                  <ChevronRight className="w-4 h-4" />
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default ChannelPreview;
