import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2'; // Import SweetAlert2
import Input from '../components/ui/input';
import Button from '../components/ui/button';

const deviceTypeOptions = [
  { value: 'Amazon Fire stick / Fire TV', label: 'Amazon Fire stick / Fire TV' },
  { value: 'Smart TV', label: 'Smart TV' },
  { value: 'Android Device', label: 'Android Device' },
  { value: 'Mag Box / Formular Z8', label: 'Mag Box / Formular Z8' },
  { value: 'Apple Device / Mac OS', label: 'Apple Device / Mac OS' },
  { value: 'Windows Device', label: 'Windows Device' },
  { value: 'Enigma / Zgemma', label: 'Enigma / Zgemma' },
  { value: 'M3u Url', label: 'M3u Url' },
  { value: 'Other Device', label: 'Other Device' },
];

const TrialRequest = () => {
  const [phone_number, setPhoneNumber] = useState('');
  const [email, setEmail] = useState('');
  const [device_type, setDeviceType] = useState(deviceTypeOptions[0].value);
  const navigate = useNavigate();

  const validatePhoneNumber = (number) => {
    const phoneRegex = /^\+[1-9]\d{1,14}$/;
    return phoneRegex.test(number);
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const showTrialAlreadyRequestedPopup = () => {
    Swal.fire({
      icon: 'info',
      title: 'Trial Already Requested',
      text: 'It seems you have already requested a free trial. You can extend your trial to 7 days for only £4.99 or purchase a subscription.',
      showCancelButton: true,
      confirmButtonText: 'Extend Trial (7 Days)',
      cancelButtonText: 'Buy Subscription',
      confirmButtonColor: '#ff6b6b',
      cancelButtonColor: '#ffa500',
    }).then((result) => {
      if (result.isConfirmed) {
        navigate('/7days'); // Navigate to the 7-day trial extension page
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        navigate('/pricing'); // Navigate to the subscription page
      }
    });
  };

  const handleTrialRequest = async (e) => {
    e.preventDefault();

    if (!validatePhoneNumber(phone_number)) {
      Swal.fire({
        icon: 'error',
        title: 'Invalid WhatsApp Number',
        text: 'Please enter a valid WhatsApp number with country code (e.g., +123456789).',
        confirmButtonText: 'OK',
      });
      return;
    }

    if (!validateEmail(email)) {
      Swal.fire({
        icon: 'error',
        title: 'Invalid Email Address',
        text: 'Please enter a valid email address.',
        confirmButtonText: 'OK',
      });
      return;
    }

    const trialData = {
      phone_number,
      email,
      device_type,
    };

    try {
      const response = await fetch('/api/trial-request', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(trialData),
      });

      if (response.ok) {
        const result = await response.json();
        const { orderId, amount, email: responseEmail } = result;
        navigate(`/thank-you?orderId=${orderId}&amount=${amount}&email=${encodeURIComponent(responseEmail)}`);
      } else if (response.status === 400) {
        showTrialAlreadyRequestedPopup(); // Show popup if trial already requested
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'An error occurred. Please try again later.',
          confirmButtonText: 'OK',
        });
      }
    } catch (error) {
      console.error('Error submitting trial request:', error);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'An error occurred. Please try again later.',
        confirmButtonText: 'OK',
      });
    }
  };

  return (
    <div className="min-h-screen bg-gray-50 text-gray-800 flex items-center justify-center">
      <div className="container mx-auto px-6 py-12">
        <h2 className="text-3xl lg:text-4xl font-extrabold text-center mb-12 text-[rgb(255,107,107)]">
          Request a Free Trial
        </h2>

        <form onSubmit={handleTrialRequest} className="max-w-lg mx-auto bg-white p-8 rounded-lg shadow-lg">
          {/* WhatsApp Number */}
          <div className="mb-6">
            <label htmlFor="phone_number" className="block text-base lg:text-lg font-medium mb-2">
              WhatsApp Number
            </label>
            <Input
              type="tel"
              id="phone_number"
              name="phone_number"
              placeholder="Enter your WhatsApp number (e.g., +123456789)"
              value={phone_number}
              onChange={(e) => setPhoneNumber(e.target.value)}
              required
              className="w-full py-4 px-6 text-base lg:text-lg border rounded-lg"
            />
          </div>

          {/* Email */}
          <div className="mb-6">
            <label htmlFor="email" className="block text-base lg:text-lg font-medium mb-2">
              Email Address
            </label>
            <Input
              type="email"
              id="email"
              name="email"
              placeholder="Enter your email address"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              className="w-full py-4 px-6 text-base lg:text-lg border rounded-lg"
            />
          </div>

          {/* Device Type Selection */}
          <div className="mb-6">
            <label htmlFor="device_type" className="block text-base lg:text-lg font-medium mb-2">
              Select Your Device
            </label>
            <select
              id="device_type"
              name="device_type"
              value={device_type}
              onChange={(e) => setDeviceType(e.target.value)}
              className="w-full py-4 px-4 border border-gray-300 rounded-lg text-base lg:text-lg bg-white"
            >
              {deviceTypeOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>

          {/* Submit Button */}
          <Button
            type="submit"
            className="w-full bg-gradient-to-r from-[#ff6b6b] to-[#ffa500] text-white py-4 rounded-lg font-semibold text-lg flex items-center justify-center hover:from-[#ff4d4d] hover:to-[#ff9100] transition-colors duration-200"
          >
            Request Trial
          </Button>
        </form>
      </div>
    </div>
  );
};

export default TrialRequest;