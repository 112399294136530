import React from 'react';

const MAGBoxGuide = () => {
  return (
    <div className="container mx-auto px-6 py-16 mt-20">
      <h2 className="text-3xl font-extrabold mb-8 text-[#ff6b6b]">MAG Box IPTV Setup Guide</h2>
      <p className="mb-6">
        Follow these steps to set up your IPTV service on a MAG Box device:
      </p>
      <ol className="list-decimal list-inside space-y-4">
        <li>Connect your MAG Box to your TV via HDMI and power it on.</li>
        <li>Ensure the MAG Box is connected to the internet, either through Wi-Fi or an Ethernet cable.</li>
        <li>Once powered on, the device will boot up to the **System Settings** menu.</li>
        <li>Using the remote control, navigate to **Settings**, then go to **Servers**.</li>
        <li>Click on **Portals** in the server settings.</li>
        <li>In the **Portal 1 Name** field, enter the name "FlixSphere".</li>
        <li>In the **Portal 1 URL** field, enter the IPTV portal URL that was sent to you via email.</li>
        <li>Leave the other portal fields empty unless instructed otherwise.</li>
        <li>Save the settings, and the MAG Box will reboot.</li>
        <li>Once rebooted, the IPTV channels will load, and you can start watching your IPTV service on your MAG Box.</li>
      </ol>

      <div className="mt-8 mb-6 border-t-2 pt-6">
        <p>
          If you need further assistance, feel free to contact us via email at <strong>support@FlixSphere.com</strong> or through WhatsApp for a faster response.
        </p>
      </div>

      <div className="text-center mt-8">
        <a
          href="https://wa.me/447898002230?text=Hi%20I%20need%20help%20setting%20up%20IPTV%20on%20my%20MAG%20Box."
          target="_blank"
          rel="noopener noreferrer"
          className="inline-block bg-green-500 text-white font-semibold py-3 px-8 rounded-md hover:bg-green-600 transition-colors"
        >
          Contact us on WhatsApp
        </a>
      </div>
    </div>
  );
};

export default MAGBoxGuide;
