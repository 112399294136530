import React from 'react';
import { Link } from 'react-router-dom';

const HeroSection = () => {
  return (
    <section
      className="relative w-full h-screen bg-cover bg-no-repeat"
      style={{
        backgroundImage:
          'url("https://res.cloudinary.com/benejma/image/upload/v1725560947/nexustvflix/american-football_oslxda.webp")',
        backgroundPosition: 'top left', // Image starts from the top-left
        backgroundSize: 'cover', // Ensures the image covers the entire section
        marginTop: '0', // Ensure no margin at the top
        paddingTop: '0', // Remove padding at the top
      }}
    >
      {/* Dark overlay for text readability */}
      <div className="absolute inset-0 bg-black bg-opacity-50"></div>

      {/* Text section */}
      <div className="container mx-auto h-full flex flex-col justify-center items-center lg:items-start text-center lg:text-left relative z-10">
        <h1 className="text-5xl font-extrabold tracking-tight text-white sm:text-6xl lg:text-7xl">
          Welcome to Flix Sphere
        </h1>
        
        {/* Centered Paragraph */}
        <p className="max-w-[900px] text-gray-200 md:text-lg lg:text-xl mt-4 text-center lg:text-left">
          Enjoy a seamless streaming experience with our 4K Live IPTV service. Access a wide selection of channels with no buffering, anytime, anywhere.
        </p>

        {/* Buttons with adjusted margin for mobile and desktop */}
        <div className="flex flex-col lg:flex-row gap-6 justify-center lg:justify-start mt-8 lg:ml-[17%] ml-0">
          <Link
            to="/pricing"
            className="inline-flex h-14 items-center justify-center rounded-md bg-gradient-to-r from-[#ff6b6b] to-[#ffa500] px-10 text-lg font-semibold text-white shadow transition hover:from-[#ff4d4d] hover:to-[#ff9100] focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-[#ffa500]"
          >
            Start Trial
          </Link>
          <Link
            to="/pricing"
            className="inline-flex h-14 items-center justify-center rounded-md border border-gray-300 bg-white px-10 text-lg font-semibold text-gray-800 shadow-sm transition hover:bg-[#ffa500] hover:text-white focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-[#ffa500]"
          >
            View Pricing
          </Link>
        </div>
      </div>
    </section>
  );
};

export default HeroSection;