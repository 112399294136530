import React from 'react';
import { useLocation } from 'react-router-dom';
import { Check, Mail, Clock } from 'lucide-react';

// Custom hook to parse query parameters from URL
const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const ThankYouPage = () => {
  const query = useQuery();

  // Extract details from query parameters
  const email = query.get('email');
  const orderId = query.get('orderId');
  const amount = query.get('amount');
  const paymentStatus = query.get('paymentStatus') || 'Success';

  // Check if the required values exist before rendering
  if (!email || !orderId || !amount) {
    return <div className="text-center text-gray-600">Loading or missing information...</div>;
  }

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50 text-gray-800">
      <main className="w-full max-w-3xl mx-auto p-6">
        <div className="text-center mb-10">
          <Check className="h-14 w-14 text-green-500 mx-auto" />
          <h2 className="text-3xl font-bold text-[#ff6b6b] mt-4">Thank You for Your Order!</h2>
          <p className="text-lg text-gray-500 mt-2">Your order has been successfully placed!</p>
        </div>

        {/* Order Details and Delivery Time Section */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          
          {/* Order Details */}
          <section className="p-6 bg-white rounded-lg shadow-lg border border-gray-200">
            <h3 className="text-xl font-semibold text-[#ff6b6b] mb-4">Order Details</h3>
            <div className="space-y-4">
              <div className="flex items-center">
                <Mail className="h-6 w-6 text-[#ff6b6b] mr-2" />
                <div>
                  <span className="block text-sm text-gray-500">Email</span>
                  <p className="text-lg font-medium text-gray-800">{email}</p>
                </div>
              </div>

              <div className="flex items-center">
                <Check className="h-6 w-6 text-green-500 mr-2" />
                <div>
                  <span className="block text-sm text-gray-500">Order ID</span>
                  <p className="text-lg font-medium text-gray-800">{orderId}</p>
                </div>
              </div>

              <div className="flex items-center">
                <Clock className="h-6 w-6 text-gray-500 mr-2" />
                <div>
                  <span className="block text-sm text-gray-500">Amount</span>
                  <p className="text-lg font-medium text-gray-800">${amount}</p>
                </div>
              </div>

              <div className="flex items-center">
                <Check className="h-6 w-6 text-green-500 mr-2" />
                <div>
                  <span className="block text-sm text-gray-500">Payment Status</span>
                  <p className="text-lg font-medium text-gray-800">{paymentStatus}</p>
                </div>
              </div>
            </div>
          </section>

          {/* Delivery Info */}
          <section className="p-6 bg-white rounded-lg shadow-lg border border-gray-200">
            <div className="flex items-center mb-4">
              <Clock className="h-6 w-6 text-gray-500 mr-2" />
              <h3 className="text-xl font-semibold text-[#ff6b6b]">Delivery Time</h3>
            </div>
            <p className="text-lg text-gray-600">
              Your order will be delivered between <strong>5 minutes and 4 hours</strong>.
            </p>
            <p className="mt-2 text-lg text-gray-600">
              For faster delivery, contact us using one of the options below.
            </p>

            {/* Contact Buttons */}
            <div className="mt-6 space-y-3">
              <a
                href={`mailto:support@nexusagency.com`}
                className="block w-full bg-[#ff6b6b] text-white py-3 rounded-lg font-semibold text-center hover:bg-[#ff4f4f] transition duration-200"
              >
                <Mail className="inline-block h-5 w-5 mr-2" />
                Contact Us via Email
              </a>
              <a
                href="https://wa.me/447898002230?text=Hello%2C%20I%20am%20interested%20in%20getting%20fast%20delivery%20for%20my%20order.%20Could%20you%20please%20assist%20me%20with%20the%20Nexus%20TV%20Flix%20IPTV%20service%3F"
                className="block w-full bg-[#25D366] text-white py-3 rounded-lg font-semibold text-center hover:bg-[#1da851] transition duration-200"
              >
                <img
                  src="https://res.cloudinary.com/benejma/image/upload/v1727394558/whatsapp_gzt1tp.png"
                  alt="WhatsApp"
                  className="inline-block h-5 w-5 mr-2"
                />
                Contact Us on WhatsApp
              </a>
            </div>
          </section>
        </div>
      </main>
    </div>
  );
};

export default ThankYouPage;
