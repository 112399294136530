// In alert.js
export const Alert = ({ children, className = '', ...props }) => {
    return (
      <div className={`p-4 rounded-md ${className}`} role="alert" {...props}>
        {children}
      </div>
    );
  };
  
  // If you have an Alert.Description component
  Alert.Description = ({ children, className = '', ...props }) => {
    return (
      <div className={`text-sm ${className}`} {...props}>
        {children}
      </div>
    );
  };