import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { useState } from 'react';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/Home';
import Pricing from './pages/Pricing';
import TrialRequest from './pages/TrialRequest';
import Installation from './pages/Installation';
import Blogs from './pages/Blogs';
import ContactUs from './pages/ContactUs';
import Login from './pages/Login'; // Import the new Login component
import Dashboard from './pages/Dashboard'; // Import the Dashboard component
import Reseller from './pages/reseller'; // Corrected Reseller case
import AdminDashboard from './pages/admindashboard'; // Corrected AdminDashboard case
import ThankYouPage from './pages/ThankYouPage'; // Corrected ThankYouPage path


// Importing all guide components
import AppleGuide from './components/InstallationGuides/AppleGuide';
import AndroidGuide from './components/InstallationGuides/AndroidGuide';
import SamsungGuide from './components/InstallationGuides/SamsungGuide';
import FirestickGuide from './components/InstallationGuides/FirestickGuide';
import LGSmartTVGuide from './components/InstallationGuides/LGSmartTVGuide';
import MagBoxGuide from './components/InstallationGuides/MagBoxGuide';
import IptvSmartersGuide from './components/InstallationGuides/IptvSmartersGuide';
import WindowsDeviceGuide from './components/InstallationGuides/WindowsDeviceGuide';
import EnigmaZgemmaGuide from './components/InstallationGuides/EnigmaZgemmaGuide';

// Import the ScrollToTop component
import ScrollToTop from './components/ScrollToTop';
import SevenDays from './pages/sevendays';

// Move blogsData here
const blogsData = [
  // ... Your existing blog data ...
];

function App() {
  const [selectedPlan, setSelectedPlan] = useState(null);

  const handlePlanSelect = (plan) => {
    setSelectedPlan(plan);
  };

  return (
    <Router>
      <ScrollToTop /> {/* Add ScrollToTop component here */}
      <Header />
      <Routes>
        <Route path="/" element={<Home onPlanSelect={handlePlanSelect} />} />
        <Route path="/pricing" element={<Pricing defaultPlan={selectedPlan} onPlanSelect={handlePlanSelect} />} />
        <Route path="/trialrequest" element={<TrialRequest />} />
        <Route path="/installation" element={<Installation />} />
        <Route path="/reseller" element={<Reseller />} /> {/* Corrected path */}
        <Route path="/admindashboard" element={<AdminDashboard />} /> {/* Corrected path */}
        {/* Adding routes for all guides */}
        <Route path="/installation/apple" element={<AppleGuide />} />
        <Route path="/installation/android" element={<AndroidGuide />} />
        <Route path="/installation/samsung" element={<SamsungGuide />} />
        <Route path="/installation/firestick" element={<FirestickGuide />} />
        <Route path="/installation/lgsmarttv" element={<LGSmartTVGuide />} />
        <Route path="/installation/magbox" element={<MagBoxGuide />} />
        <Route path="/installation/iptvsmarters" element={<IptvSmartersGuide />} />
        <Route path="/installation/windowsdevice" element={<WindowsDeviceGuide />} />
        <Route path="/installation/enigma" element={<EnigmaZgemmaGuide />} />
        {/* Blogs and blog post routes */}
        <Route path="/blogs" element={<Blogs blogsData={blogsData} />} />
        {/* Contact Us route */}
        <Route path="/contact" element={<ContactUs />} />
        {/* New Login and Dashboard routes */}
        <Route path="/login" element={<Login />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/7days" element={<SevenDays/>} />
        {/* Add the Thank You page route */}
        <Route path="/thank-you" element={<ThankYouPage />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
