import React from 'react';
import { FaEnvelope, FaWhatsapp } from 'react-icons/fa';

const ContactUs = () => {
  return (
    <div className="relative">
      {/* Spacer div to push content below fixed header */}
      <div className="h-16 md:h-20"></div>
      
      <div className="bg-gradient-to-b from-gray-100 to-white py-16 px-4 sm:px-6 lg:px-8">
        <div className="max-w-3xl mx-auto">
          <h2 className="text-4xl font-extrabold text-center mb-12 text-gray-900">
            Contact <span className="text-transparent bg-clip-text bg-gradient-to-r from-[#ff6b6b] to-[#ffa500]">Nexustvflix</span>
          </h2>
          
          <div className="bg-white rounded-xl shadow-lg overflow-hidden p-8">
            <p className="text-gray-600 mb-8 text-center">
              We're here to help! Reach out to us through email or WhatsApp for any questions or support.
            </p>
            
            <div className="space-y-6">
              <a href="mailto:support@nexustvflix.com" 
                 className="flex items-center justify-center px-6 py-3 border border-transparent text-base font-medium rounded-md text-white bg-gradient-to-r from-[#ff6b6b] to-[#ffa500] hover:from-[#ff8c8c] hover:to-[#ffb732] transition duration-300 ease-in-out">
                <FaEnvelope className="mr-2" />
                Email Us
              </a>
              
              <a href="https://wa.me/1234567890" 
                 target="_blank" 
                 rel="noopener noreferrer" 
                 className="flex items-center justify-center px-6 py-3 border border-transparent text-base font-medium rounded-md text-white bg-[#25D366] hover:bg-[#128C7E] transition duration-300 ease-in-out">
                <FaWhatsapp className="mr-2" />
                WhatsApp Us
              </a>
            </div>
            
            <div className="mt-8 text-center text-sm text-gray-500">
              Our support team is available Monday to Friday, 9AM to 5PM EST.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;