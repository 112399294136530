import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <header className="bg-white shadow-md text-foreground fixed w-full top-0 left-0 z-20">
      <div className="container mx-auto flex items-center justify-between py-4 px-4 md:px-6">
        <Link to="/" className="flex items-center gap-2">
          <TvIcon className="h-6 w-6 text-[#ff6b6b]" />
          <span className="text-xl font-bold text-gray-800">Flix Sphere</span>
        </Link>
        <button
          className="md:hidden block text-gray-500 focus:outline-none"
          onClick={() => setIsOpen(!isOpen)}
        >
          <MenuIcon className="h-6 w-6" />
        </button>
        <nav className="hidden md:flex items-center gap-6">
          {navLinks()}
        </nav>
        {isOpen && (
          <div className="fixed inset-0 bg-gray-900 text-white z-20 flex flex-col items-center justify-center space-y-6 text-xl">
            <button
              className="absolute top-4 right-6 text-gray-300"
              onClick={() => setIsOpen(false)}
            >
              <CloseIcon className="h-8 w-8" />
            </button>
            {navLinks(true)}
          </div>
        )}
      </div>
    </header>
  );
};

const navLinks = (isMobile = false) => (
  <>
    <Link
      to="/"
      className={`${
        isMobile ? 'block text-center' : 'inline-flex'
      } h-10 px-4 py-2 rounded-md hover:text-[#ffa500]`}
    >
      Home
    </Link>
    <Link
      to="/pricing"
      className={`${
        isMobile ? 'block text-center' : 'inline-flex'
      } h-10 px-4 py-2 rounded-md hover:text-[#ffa500]`}
    >
      Pricing
    </Link>
    <Link
      to="/installation"
      className={`${
        isMobile ? 'block text-center' : 'inline-flex'
      } h-10 px-4 py-2 rounded-md hover:text-[#ffa500]`}
    >
      Installation
    </Link>
    <Link
      to="/blogs"
      className={`${
        isMobile ? 'block text-center' : 'inline-flex'
      } h-10 px-4 py-2 rounded-md hover:text-[#ffa500]`}
    >
      Blogs
    </Link>
    <Link
      to="/contact"
      className={`${
        isMobile ? 'block text-center' : 'inline-flex'
      } h-10 px-4 py-2 rounded-md hover:text-[#ffa500]`}
    >
      Contact
    </Link>
    <Link
      to="/login"
      className={`${
        isMobile ? 'block text-center' : 'inline-flex'
      } h-10 px-4 py-2 rounded-md hover:text-[#ffa500]`}
    >
      Account
    </Link>
    <Link
      to="/pricing"
      className={`${
        isMobile ? 'block' : 'inline-flex'
      } text-center bg-gradient-to-r from-[#ff6b6b] to-[#ffa500] py-2 px-6 rounded-lg text-white hover:from-[#ff4d4d] hover:to-[#ff9100]`}
    >
      Start Trial
    </Link>
  </>
);

export default Header;

function CloseIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <line x1="18" y1="6" x2="6" y2="18" />
      <line x1="6" y1="6" x2="18" y2="18" />
    </svg>
  );
}

function MenuIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <line x1="4" x2="20" y1="12" y2="12" />
      <line x1="4" x2="20" y1="6" y2="6" />
      <line x1="4" x2="20" y1="18" y2="18" />
    </svg>
  );
}

function TvIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <rect width="20" height="15" x="2" y="7" rx="2" ry="2" />
      <polyline points="17 2 12 7 7 2" />
    </svg>
  );
}
